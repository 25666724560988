<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量查询')"
        :class="crumbs == '批量查询' ? 'active' : 'left'"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : 'left'"
        >订单查询</span
      >
    </div>
    <!-- 批量订单查询 -->
    <div v-if="crumbs == '批量查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <el-input
              v-model="queryBatch.keyword"
              maxlength="128"
              suffix-icon="el-icon-search"
              placeholder="商户名称"
            ></el-input>
          </div>
          <div class="item">
            <i>通知状态</i>
            <el-select
              v-model="queryBatch.reachStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="已到达" value="true"></el-option>
              <el-option label="未响应" value="false"></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>通知结果</i>
            <el-select
              v-model="queryBatch.notifyStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="成功" value="SUCCESS"></el-option>
              <el-option label="失败" value="FAIL"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>通知时间</i>
            <el-date-picker
              v-model="queryBatch.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="queryBatch.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button class="searchBt" @click="onSearch">查询</el-button>
            <el-button v-auth="'OPERATION:NOTICES:PAY/SYNC'" class="outputBt" @click="synchronizationAll"
              >批量通知</el-button
            >
          </div>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>
            <td>
              <p>代付订单号</p>
              <p>代付流水号</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(item, index) in queryBatchList" :key="index">
            <td>
              <p>{{ item.notifyTime }}</p>
            </td>
            <td>
              <p>{{ item.mchName }}</p>
            </td>
            <td>
              <p>{{ item.mchPayNo }}</p>
              <p>{{ item.plfPayNo }}</p>
            </td>
            <td>
              <p v-if="item.reachStatus == true">已到达</p>
              <p v-if="item.reachStatus == false || item.reachStatus == null">
                未响应
              </p>
            </td>
            <td>
              <p v-if="item.notifyStatus == 'SUCCESS'">成功</p>
              <p v-if="item.notifyStatus == 'FAIL'">失败</p>
            </td>
            <td>
              <p>{{ item.resultCode }}</p>
            </td>
            <td>
              <p>{{ item.resultMessage }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <a
                  v-auth="'OPERATION:NOTICES:PAY/DETAIL'"
                  href="javascript:;"
                  @click="detail(item)"
                  class="lianjie"
                  >详情</a
                >
                <i
                  v-auth="'OPERATION:NOTICES:PAY/SYNC'"
                  class="lianjie"
                  @click="synchronization(item)"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChangeByQueryBatch"
        @current-change="handleCurrentChangeByQueryBatch"
        :current-page="queryBatch.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryBatch.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNumByQueryBatch"
      >
      </el-pagination>
    </div>
    <!-- 按订单号查询 -->
    <div v-if="crumbs == '订单查询'">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <el-select
              v-model="queryOrder.orderNoType"
              placeholder=""
              style="width: 170px; margin-right: 20px"
            >
              <el-option label="代付订单号" value="mchPayNo"></el-option>
              <el-option label="代付流水号" value="plfPayNo"></el-option>
            </el-select>
            <el-input
              v-model="queryOrder.orderNo"
              maxlength="128"
              style="width:calc(100% - 170px - 20px)"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + orderNoType[queryOrder.orderNoType]"
            ></el-input>
          </div>
          <div class="itembtn itemTwobtn">
            <el-button class="searchBt" @click="onSearchOrder">查询</el-button>
          </div>
        </div>
      </div>
      <div class="tab1" style="min-height: 700px">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>商户名称</p>
            </td>
            <td>
              <p>代付订单号</p>
              <p>代付流水号</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(item, index) in queryOrderList" :key="index">
            <td>
              <p>{{ item.notifyTime }}</p>
            </td>
            <td>
              <p>{{ item.mchName }}</p>
            </td>
            <td>
              <p>{{ item.mchPayNo }}</p>
              <p>{{ item.plfPayNo }}</p>
            </td>
            <td>
              <p v-if="item.reachStatus == true">已到达</p>
              <p v-if="item.reachStatus == false || item.reachStatus == null">
                未响应
              </p>
            </td>
            <td>
              <p v-if="item.notifyStatus == 'SUCCESS'">成功</p>
              <p v-if="item.notifyStatus == 'FAIL'">失败</p>
            </td>
            <td>
              <p>{{ item.resultCode }}</p>
            </td>
            <td>
              <p>{{ item.resultMessage }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <a
                  v-auth="'OPERATION:NOTICES:PAY/DETAIL'"
                  href="javascript:;"
                  @click="detail(item)"
                  class="lianjie"
                  >详情</a
                >
                <i
                  v-auth="'OPERATION:NOTICES:PAY/SYNC'"
                  class="lianjie"
                  @click="synchronization(item)"
                  >通知</i
                >
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  agnpayQueryBatch,
  agnpayQueryOrder,
  agnpayPost,
} from "@/api/operation/inform.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      value: "",
      crumbs: "批量查询",
      orderNoType: {
        mchPayNo: "代付订单号",
        plfPayNo: "代付流水号",
      },
      queryBatch: {
        // 批量查询
        pageNo: 1,
        pageSize: 10,
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        notifyStatus: "",
        reachStatus: "",
        keyword: "",
      },
      queryOrder: {
        //订单查询
        pageNo: 1,
        pageSize: 10,
        orderNoType: "mchPayNo",
        orderNo: "",
      },
      totalNumByQueryBatch: 0,
      totalNumByQueryOrder: 0,
      queryBatchList: [],
      queryOrderList: [],
    };
  },
  computed: {
    ...mapState({
      applyforDetail: "operation_applyfor",
    }),
  },
  created() {
    if (this.applyforDetail) {
      this.queryBatch = this.applyforDetail.queryBatch;
      this.queryOrder = this.applyforDetail.queryOrder;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.queryBatch.pageNo;
    });
    this.getLists(false);
    this.$enter(this.$route.path, this.search);
  },
  activated() {
    if (this.applyforDetail) {
      this.queryBatch = this.applyforDetail.queryBatch;
      this.queryOrder = this.applyforDetail.queryOrder;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.queryBatch.pageNo;
    });
    this.getLists(false);
  },
  methods: {
    ...mapMutations({
      setApplyfor: "operation_setApplyfor",
    }),
    search() {
      if (this.crumbs == "批量查询") {
        this.onSearch();
      } else {
        this.onSearchOrder();
      }
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
      if (this.crumbs == "批量查询") {
        agnpayQueryBatch(this.queryBatch)
          .then((res) => {
            this.queryBatchList = res.resultData.notices;
            if (this.queryBatchList.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
            this.totalNumByQueryBatch = res.resultData.totalNum;
          })
          .catch();
      }
      // else if(this.crumbs == '订单查询'){
      //   agnpayQueryOrder(this.queryOrder).then(res => {
      //     this.queryOrderList = res.resultData
      //     // this.totalNumByQueryOrder = res.resultData.totalNum

      //   }).catch()
      // }
    },
    getLists(reset = true) {
      if (reset) {
        this.queryBatch.pageNo = 1;
      }
      this.queryBatch.startTime =
        this.queryBatch.startTime == null ? "" : this.queryBatch.startTime;
      this.queryBatch.endTime =
        this.queryBatch.endTime == null ? "" : this.queryBatch.endTime;
      this.queryBatch.keyword = this.queryBatch.keyword.trim();

      agnpayQueryBatch(this.queryBatch)
        .then((res) => {
          this.queryBatchList = res.resultData.notices;
          if (this.queryBatchList.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNumByQueryBatch = res.resultData.totalNum;
        })
        .catch();
    },
    getListsByOrder() {
      let params = {
        pageNo: this.queryOrder.pageNo,
        pageSize: this.queryOrder.pageSize,
        orderNoType: this.queryOrder.orderNoType,
        orderNo: this.queryOrder.orderNo.trim(),
      };
      if (!params.orderNo) {
        this.$message.error("请输入" + this.orderNoType[this.queryOrder.orderNoType]);
        return;
      }
      agnpayQueryOrder(params)
        .then((res) => {
          this.queryOrderList = res.resultData;
          if (this.queryOrderList.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          // this.totalNumByQueryOrder = res.resultData.totalNum
        })
        .catch();
    },
    handleSizeChangeByQueryBatch(size) {
      this.queryBatch.pageSize = size;
      this.getLists();
    },
    handleCurrentChangeByQueryBatch(page) {
      this.queryBatch.pageNo = page;
      this.getLists(false);
    },
    handleSizeChangeByQueryOrder(size) {
      this.queryOrder.pageSize = size;
      this.getListsByOrder();
    },
    handleCurrentChangeByQueryOrder(page) {
      this.queryOrder.pageNo = page;
      this.getListsByOrder();
    },
    onSearch() {
      this.getLists();
    },

    onSearchOrder() {
      // this.navClick('订单查询') ;
      this.getListsByOrder();
    },
    detail(row) {
      this.$router.push({
        path: "/operation/applyfor_xq",
        query: {
          payId: row.payId,
        },
      });
    },
    synchronization(item) {
      let data = {
        payIds: [],
      };
      data.payIds.push(item.payId);
      agnpayPost(data)
        .then((res) => {
          if (res.resultStatus) {
            this.$message.success("通知成功");
          } else {
            this.$message.error("通知失败");
          }
        })
        .catch();
    },
    synchronizationAll() {
      let data = {
        payIds: [],
      };
      this.queryBatchList.forEach((item) => {
        data.payIds.push(item.payId);
      });
      if (data.payIds.length < 1) {
        this.$message.error("缺少通知数据");
        return;
      }
      agnpayPost(data)
        .then((res) => {
          if (res.resultStatus) {
            this.$message.success("通知成功");
          } else {
            this.$message.error("通知失败");
          }
        })
        .catch();
    },
  },
  beforeDestroy() {
    let data = {
      queryBatch: this.queryBatch,
      queryOrder: this.queryOrder,
    };
    this.setApplyfor(data);
  },
};
</script>
<style scoped>

</style>
